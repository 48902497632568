.ant-menu-horizontal {
  border-bottom: none;
}

.mobile-menu {
  display: none;
}

.nav-button {
  &:active {
    background-color: rgba(0, 0, 0, 0);

    button {
      color: #c4742d;
    }
  }

  button {
    color: #333;

    &:hover {
      color: #c4742d;
    }
  }
}

.ant-menu {
  .ant-menu-item-group-title {
    display: none;
  }

  .ant-menu-title-content {
    margin-left: 0px !important;
  }
}

.ant-menu-item-only-child {
  padding: 0 28px !important;
}

.menu-group {
  ul {
    display: flex;
    align-items: center;
  }

  &.nav {
    width: 100%;
    display: flex;
    // justify-content: center; (replacing social icons)
    justify-content: flex-end;
  }

  &.social {
    width: 100%;
    align-items: center;

    ul {
      height: 100%;
      justify-content: flex-end;
    }
  }
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 75px;
  height: 75px;
  margin: 10px 7px 0 46px;
}

.logo-text {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 0;

  h1 {
    color: #040f4c;
    font-size: 27px !important;
    line-height: 1.04;
    margin: 0 !important;

    &.plural {
      color: #215484;
    }
  }
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .logo-img {
    margin: 10px 7px 0 20px;
  }

  .mobile-menu {
    display: flex;
    justify-content: space-between;
  }

  .mobile-ant-menu {
    top: 32px;
    position: absolute;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%) !important;
    width: 50%;
    right: 0px;
    z-index: 1000;
    border: 0.1px solid rgb(0 0 0 / 2%);
  }

  .ant-menu-title-content {
    text-align: center;
    margin-right: 13px;
  }

  .home-link {
    display: flex;
    align-items: center;
  }

  .logo-text {
    margin: 10px 0 0 0;

    h1 {
      text-align: left;
      line-height: 1 !important;
    }
  }
}

.about-video {
  max-width: 45vw;
}

@media (max-width: 768px) {
  .about-video {
    max-width: 85vw;
  }
}
